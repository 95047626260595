<template>
  <div class="main content-layout-fixer">
    <div v-if="surveysList.length === 0" class="warning-center">
      <VueText class="warning-card" color="gray-800" sizeLevel="5" weightLevel="2">
        Cevaplanmamış anketiniz bulunmamaktadır
      </VueText>
    </div>
    <div v-else class="content-card-list">
      <ul class="list-container">
        <li class="content-card" v-for="surveys in surveysList" :key="surveys.surveyId">
          <div
            class="feed-item-container"
            @click="goToSurvey(surveys.surveyCategoryVal, surveys.surveyId)"
          >
            <div>
              <img
                v-if="surveys.image"
                :src="surveys.image"
                class="BrandMediaContentBox"
                :alt="surveys.surveyName"
              />
              <img
                v-else
                src="~@/assets/images/survey.jpeg"
                class="BrandMediaContentBox"
                alt="Anket"
              />
            </div>
            <div class="feed-item-summary">
              <VueText :color="'grey-600'" sizeLevel="4" weightLevel="3">{{
                surveys.surveyName
              }}</VueText>
            </div>
          </div>
        </li>
      </ul>
      <div v-if="npsSurvey">
        <NpsSurvey ref="npsSurveyRef" :npsSurveyId="npsSurveyId" />
      </div>
    </div>
  </div>
</template>
<script>
import { Gamification } from '@/services/Api/index';
import VueText from '@/components/shared/VueText/VueText.vue';
import NpsSurvey from '@/views/pages/secure/NpsSurvey.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'SurveysHome',
  components: { VueText, NpsSurvey },

  data() {
    return {
      surveysList: [],
      npsSurvey: false,
      npsSurveyId: null,
      npsSurveyRef: null,
    };
  },
  beforeMount() {
    this.getSurveyList();
  },
  created() {
    Object.defineProperty(this.$refs, 'npsSurveyRef', {
      get: function() {
        return this.npsSurveyRef;
      }.bind(this),
      set: function(newValue) {
        this.npsSurveyRef = newValue;
      }.bind(this),
      enumerable: true,
    });
  },
  watch: {
    npsSurveyRef: {
      handler(newValue, oldValue) {
        //sayfa ilk açıldığında değilde bi nps oluşturulup kapandığında yeniden açmak için
        if (!newValue && oldValue) this.npsSurvey = !this.npsSurvey;
      },
      deep: false,
      immediate: true,
    },
    getNpsFinished: {
      handler(isFinished) {
        if (isFinished) {
          this.getSurveyList();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getSurveyList() {
      Gamification.getIncompletedSurveys().then(res => {
        this.surveysList = res.data.Data.inCompletedSurveys;
      });
    },
    goToSurvey(val, Id) {
      if (val !== 6 && val !== 8) {
        this.$router.push(`/secure/surveys/${Id}`);
      } else {
        this.npsSurvey = !this.npsSurvey;
        this.npsSurveyId = Id;
      }
    },
  },
  computed: {
    ...mapGetters('app', ['getNpsFinished']),
  },
};
</script>

<style lang="scss" scoped>
.main {
  min-height: 80vh;
}
.content-card-list {
  margin: 20px 0;
  padding-left: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  .list-container {
    display: grid;
    grid-template-columns: repeat(3, 321px);
    grid-gap: 20px;
    list-style: none;
  }

  .content-card {
    border: 1px solid #b4c2d3;
    .feed-item-container {
      margin-bottom: 45px;

      .BrandMediaContentBox {
        min-height: 179px;
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
      }
      .feed-item-summary {
        padding: 20px;
      }
    }
  }
  .link {
    text-decoration: none;
    color: #23303d;
  }
}
.warning-center {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100%;
  .warning-card {
    padding: palette-space-level('30');
    box-shadow: 0 0 palette-space-level('10') 0 rgba(0, 0, 0, 0.2);
  }
}
</style>
